import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js"
import { cognitoConfig } from "./config"

const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId,
})

export function signUp(username, email, password) {
  // Sign up implementation
}

export function confirmSignUp(username, code) {
  // Confirm sign up implementation
}

export function signIn(username, password) {
  
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    })

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err);
            return;
          }
          resolve({result, cognitoUser, session});
        });
      },
      onFailure: (err) => {
        reject(err)
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // User needs to set a new password
        resolve({result:{
          newPasswordRequired: true,
          cognitoUser: cognitoUser,
          userAttributes: userAttributes,
          requiredAttributes: requiredAttributes,
      }, cognitoUser});
      },
    })
  })
}
export function completeNewPasswordChallenge(cognitoUser, newPassword) {
  return new Promise((resolve, reject) => {
    try {
      if (!(cognitoUser instanceof CognitoUser)) {
        throw new Error("Invalid Cognito user object");
      }
      cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          console.log(err);
          reject(err);
        },
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}
export function changePassword(oldPassword, newPassword) {
  
  const cognitoUser = userPool.getCurrentUser();
  return new Promise((resolve, reject) => {
    
    try {
      if (!(cognitoUser instanceof CognitoUser)) {
        throw new Error("Invalid Cognito user object");
      }
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err)
          return
        }
        resolve(session)
      })
      cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    } catch (error) {
      reject(error);
    }
  });
}
export function forgotPassword(username) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve()
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export function confirmPassword(username, confirmationCode, newPassword) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.confirmPassword(confirmationCode, newPassword, {
      onSuccess: () => {
        resolve()
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export function signOut() {
  const cognitoUser = userPool.getCurrentUser()
  if (cognitoUser) {
    cognitoUser.signOut()
  }
}
export async function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser()
    

    if (!cognitoUser) {
      reject(new Error("No user found"))
      return
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      }
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err)
          return
        }
        const userData = attributes.reduce((acc, attribute) => {
          acc[attribute.Name] = attribute.Value
          return acc
        }, {})

        resolve({ ...userData, username: cognitoUser.username, session })
      })
    })
  })
}

export function getSession() {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("No user found"))
      return
    }
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      }
      resolve(session)
    })
  })
}

 // Retrieve the users current token if they have a session, otherwise returns null
export async function getCurrentToken() {
  try {
    const session = await getSession();
    return session.getIdToken().getJwtToken();
  } catch (err) {
    return null;
  }
}
export function refreshSession() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser()
    if (!cognitoUser) {
      reject(new Error("No user found"))
      return
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      }
      if (session.isValid()) {
        resolve(session)
        return
      }

      // Refresh the session
      const refreshToken = session.getRefreshToken()
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          reject(err)
          return
        }
        resolve(session)
      })
      
    })
  })
}

export function fetchUserRoles () {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser()
    if (!cognitoUser) {
      reject(new Error("No user found"))
      return
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      }
      resolve(session.accessToken.payload["cognito:groups"])
    })
  })
}

export function isLoggedIn() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function(err, session) {
        if (err) {
          reject(err);
          return;
        }
        resolve(session.isValid());
      });
    } else {
      resolve(false);
    }
  });
}

