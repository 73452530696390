import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ProjectContext from "../ProjectContext";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import {
  ChevronDown,
  ChevronUp,
  Search,
  ChevronDoubleLeft,
} from "react-bootstrap-icons";
import { Collapse } from "react-bootstrap";
import Pagination from "../components/Pagination";
import Select from "react-select";
import { formatDate } from "../utils";
import { useLocation, useNavigate } from "react-router-dom";

function FileLog() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [logs, setLogs] = useState([]);
  const { userId, projectName, projectId, roles } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const pageSize = 10;
  const [showFilters, setShowFilters] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [applyFilters, setApplyFilters] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalListCount, setTotalListCount] = useState(0);
  const location = useLocation();
  const fileId = location.state ? location.state.id : null;
  const currentPageLast = location.state ? location.state.currentPage : null;
  const navigate = useNavigate();
  const [lastFileId, setLastFileId] = useState(null);
  useEffect(() => {
    setLastFileId(fileId);
  }, [fileId]);
  useEffect(() => {
    if ((userId && (currentPage || searchQuery)) || applyFilters) {
      fetchLogs();
      if (applyFilters) {
        setApplyFilters(false);
      }
    }
  }, [userId, currentPage, searchQuery, applyFilters]);

  const fetchLogs = async () => {
    setIsLoading(true);
    try {
      const token = await getCurrentToken();
      const formattedStartDate = startDate
        ? new Date(startDate).toISOString().replace("T", " ").substring(0, 19)
        : null;
      let formattedEndDate = endDate ? new Date(endDate) : null;

      // If an end date is provided, adjust it to be the end of the day
      if (formattedEndDate) {
        formattedEndDate.setHours(23, 59, 59, 999);
        formattedEndDate = formattedEndDate
          .toISOString()
          .replace("T", " ")
          .substring(0, 19);
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}file-upload/file-action-log/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            size: pageSize,
            sortColumn: "",
            sortOrder: "DESC",
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            action: selectedAction?.value,
            projectName: projectName,
            userId: selectedUser?.value,
            search: searchQuery,
            fileId: fileId,
          },
        }
      );

      setLogs(response.data.content);
      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 10 && pageInfo.totalElements % 10 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
      setIsLoading(false);
    } catch (err) {
      console.log("Error fetching logs: ", err);
      setIsLoading(false);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "ACTIVE",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "",
            projectId: projectId,
          },
        }
      );
      if (response.status === 200) {
        setUsers(
          response.data.content
            .filter((user) => !user.userGroups.includes("SuperAdmin"))
            .map((user) => ({
              value: user.id,
              label:
                user.firstName +
                " " +
                user.lastName +
                " (" +
                user.userGroups +
                ")",
            }))
        );
      } else {
        console.error("Error fetching users:", response);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // useEffect(() => {
  //   if (applyFilters || currentPage) {
  //     fetchLogs();
  //     setApplyFilters(false);
  //   }
  // }, [applyFilters]);

  const handleFilterClear = () => {
    setStartDate("");
    setEndDate("");
    setSelectedAction(null);
    setSelectedUser("");
    setApplyFilters(true);
  };
  const actions = [
    { value: "", label: "All Actions" },
    { value: "ASSIGNED", label: "Assigned" },
    { value: "REVIEWED", label: "Reviewed" },
    { value: "PROCESSED", label: "Processed" },
    { value: "VIEWED", label: "Viewed" },
    { value: "DOWNLOADED", label: "Downloaded" },
  ];
  const handleFilterApply = () => {
    setApplyFilters(true);
    setCurrentPage(1); // reset current page to 1
  };
  const handleClick = () => {
    const pathname = roles.includes("Reviewer") ? "/files" : "/documents";
    navigate(pathname, { state: { fileId: lastFileId, currentPage: currentPageLast } });
  };
  return (
    <div>
      <h2 className="fw-bold mb-0">Action Log</h2>
      <div className="py-3">
        <div className="d-flex align-items-center">
          {location.state && location.state.id && (
            <button
              onClick={handleClick}
              className="btn btn-sm btn-outline-primary px-3"
            >
              <ChevronDoubleLeft  className="dark-icon-color"/> Back
            </button>
          )}
          {roles.includes("ProjectAdmin") && (
            <div className="ms-auto">
              <div className="d-inline-block">
                <div className="input-group flex-nowrap">
                  <span
                    className="input-group-text bg-transparent"
                    id="addon-wrapping"
                  >
                    <Search />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search File Name"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
              <button
                onClick={async () => {
                  setShowFilters(!showFilters);
                  if (!showFilters) {
                    // Call fetchUsers when filters are about to be shown
                    await fetchUsers();
                  }
                }}
                aria-controls="filter-collapse"
                aria-expanded={showFilters}
                className="btn mb-2 border-0 px-2 fw-bold text-primary"
              >
                {showFilters ? (
                  <>
                    <ChevronUp /> Hide
                  </>
                ) : (
                  <>
                    <ChevronDown /> Show
                  </>
                )}{" "}
                Filters
              </button>
            </div>
          )}
        </div>
        <Collapse in={showFilters}>
          <div id="filter-collapse" className="border rounded-3 p-4">
            <div className="row">
              <div className="col-md-2">
                <div className="mb-3">
                  <label>Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="mb-3">
                  <label>End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label>Action</label>
                  <Select
                    name="actions"
                    options={actions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={setSelectedAction}
                    value={selectedAction}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label>User</label>
                  <Select
                    name="users"
                    options={users}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={setSelectedUser}
                    value={selectedUser}
                  />
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                onClick={handleFilterClear}
                className="btn btn-secondary me-2"
              >
                Clear
              </button>
              <button onClick={handleFilterApply} className="btn btn-primary">
                Filter
              </button>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="table-responsive border rounded p-2">
        {isLoading ? (
          <p>Loading...</p>
        ) : logs.length > 0 ? (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Action</th>
                  <th width="30%">File</th>
                  <th>Timestamp</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log, index) => (
                  <tr key={index}>
                    <td>{log.userName}</td>
                    <td>{log.action}</td>
                    <td>{log.fileName}</td>
                    <td>{log.createdAt ? formatDate(log.createdAt) : ""}</td>
                    <td>{log.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPageSize={totalPageSize}
              onPageChange={handlePageChange}
              totalListCount={totalListCount}
              pageSize={pageSize}
            />
          </>
        ) : (
          <p>No logs found</p>
        )}
      </div>
    </div>
  );
}

export default FileLog;
