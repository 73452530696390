import React, { useState, useEffect } from "react";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import { Modal } from "react-bootstrap";
import Select from "react-select";

function AddUser({ show, handleClose, triggerRefresh }) {
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    project: [],
  });
  const [fieldErrors, setFieldErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  // Define the options for the roles
  const roleOptions = [
    { value: "SuperAdmin", label: "Super Admin" },
    { value: "ProjectAdmin", label: "Project Admin" },
    { value: "Reviewer", label: "Reviewer" },
    { value: "Developer", label: "Developer" },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getCurrentToken();

        if (!token) {
          console.error("No token found");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}project/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: 1,
              size: 1000, // Fetch a large number of projects
              search: "",
              status: "",
              sortColumn: "name",
              sortOrder: "DESC",
            },
          }
        );

        const jsonData = response.data;

        if (Array.isArray(jsonData.content)) {
          setProjects(jsonData.content);
        } else {
          console.error("Invalid data format: content is not an array");
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear the error message for the field when the user starts typing in it
    setFieldErrors({ ...fieldErrors, [name]: "" });
  };
  const handleRoleChange = (selectedOption) => {
    let newFormData = {
      ...formData,
      role: selectedOption ? selectedOption.value : "",
    };
    // If the selected role is SuperAdmin, clear the project field
    if (selectedOption && selectedOption.value === "SuperAdmin") {
      newFormData.project = [];
    }

    setFormData(newFormData);
    // Clear the error message for the field when the user selects a role
    setFieldErrors({ ...fieldErrors, role: "" });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check for required fields
    const errors = {};
    if (!formData.firstName.trim()) {
      errors.firstName = "Please enter first name";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "Please enter last name";
    }
    if (!formData.email.trim()) {
      errors.email = "Please enter email";
    }
    if (formData.role.length === 0) {
      errors.role = "Please select at least one role";
    }

    // Set field errors
    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }
    const roleArray = [formData.role];
    // User API call
    let userId;
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}user`,
        {
          ...formData,
          projectIds: formData.project.map((projectId) => parseInt(projectId)),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      userId = response.data.id; // assuming the user ID is returned in the 'id' field
      setErrorMessage("");
    } catch (error) {
      console.error("Error creating user:", error);
      if (error.response) {
        if (error.response.status === 400) {
          // Bad Request error
          const errorMessage = error.response.data;
          setErrorMessage(
            errorMessage || "An error occurred while creating the user."
          );
        }
      } else {
        setErrorMessage("An error occurred while creating the user."); // Generic error message
      }
      return; // if user creation fails, don't proceed to role assignment
    }

    // Role API call
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}user/groups`,
        {
          userId: userId,
          groups: roleArray,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      triggerRefresh(); // Refresh the user list
      handleClose();
    } catch (error) {
      console.error("Error adding role:", error);
    }
  };
  const handleProjectChange = (selectedOption) => {
    setFormData({
      ...formData,
      project: selectedOption
        ? selectedOption.map((option) => option.value)
        : [],
    });
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                {fieldErrors.firstName && (
                  <div className="text-danger">{fieldErrors.firstName}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                {fieldErrors.lastName && (
                  <div className="text-danger">{fieldErrors.lastName}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {fieldErrors.email && (
                  <div className="text-danger">{fieldErrors.email}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Role</label>
                <Select
                  options={roleOptions}
                  value={roleOptions.find(
                    (option) => formData.role === option.value
                  )}
                  onChange={handleRoleChange}
                  name="roles"
                />
                {fieldErrors.role && (
                  <div className="text-danger">{fieldErrors.role}</div>
                )}
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="mb-3">
                <label>Project</label>
                <Select
                  options={projects.map((project) => ({
                    value: project.id,
                    label: project.name,
                  }))}
                  value={formData.project.map((projectId) => ({
                    value: projectId,
                    label: projects.find((project) => project.id === projectId)
                      .name,
                  }))}
                  onChange={handleProjectChange}
                  name="projects"
                  isMulti
                  isDisabled={formData.role === "SuperAdmin" || formData.role === "Developer"}
                />
              </div>
            </div>
          </div>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <div className="mb-3 text-end">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={handleClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}

export default AddUser;
