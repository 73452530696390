import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ProjectContext from "../ProjectContext";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import AddTitleForm from "../components/titles/AddTitle";
import Pagination from "../components/Pagination";
import {
  Search,
  ChevronDown,
  ChevronUp,
  PencilSquare,
  Trash3,
} from "react-bootstrap-icons";
import { Collapse } from "react-bootstrap";
import Select from "react-select";
import EditTitleForm from "../components/titles/EditTitle";

function TitleList() {
  const [titles, setTitles] = useState([]);
  const { projectId, projectName } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const pageSize = 10;
  const [filterType, setFilterType] = useState("");
  const [filterParentId, setFilterParentId] = useState("");
  const [searchName, setSearchName] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [parentOptions, setParentOptions] = useState([]);
  const [applyFilters, setApplyFilters] = useState(false);
  const [filtersUpdated, setFiltersUpdated] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [titleToEdit, setTitleToEdit] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [totalListCount, setTotalListCount] = useState(0);

  const fetchParentTitles = async () => {
    const params = {
      page: 0,
      size: "",
      projectId: projectId,
      type: "SECTION",
    };
    const token = await getCurrentToken();
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}issue/title-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      }
    );
    const parentTitles = response.data.content;
    const filteredParentTitles = parentTitles.filter(
      (title) => title.project.id === projectId
    );
    const parentTitleOptions = filteredParentTitles.map((title) => ({
      value: title.id,
      label: title.name,
    }));

    setParentOptions(parentTitleOptions);
  };
  const fetchTitles = async () => {
    setIsLoading(true);
    try {
      const token = await getCurrentToken();
      const params = {
        page: currentPage,
        size: pageSize,
        sortColumn: "name",
        sortOrder: "DESC",
        projectId: projectId,
        search: searchName,
        type: filterType?.value,
        parentId: filterParentId?.value,
      };
      // if (applyFilters) {
      //   params.type = filterType?.value;
      //   params.parentId = filterParentId?.value;
      // }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}issue/title-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      setTitles(response.data.content);
      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 10 && pageInfo.totalElements % 10 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching titles:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (projectId && (applyFilters || currentPage)) {
      fetchTitles();
      if (applyFilters) {
        setApplyFilters(false);
      }
    }
  }, [projectId, applyFilters, currentPage, searchName, parentOptions]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const clearFilters = () => {
    setFilterType("");
    setFilterParentId("");
    setApplyFilters(true);
  };
  const typeOptions = [
    { value: "SECTION", label: "Section" },
    { value: "SUBSECTION", label: "Subsection" },
    { value: "ISSUE_NATURE", label: "Issue Nature" },
    { value: "ISSUE_TYPE", label: "Issue Type" },
    {
      value: "TEMPLATE_CHANGE_TYPE",
      label: "Template change type",
    },
    { value: "TAG", label: "Tag" },
  ];

  const editTitle = async (title) => {
    const token = await getCurrentToken();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}issue/title`,
        title,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error editing title:", error);
    }
  };
  const deleteTitle = async (titleId) => {
    const token = await getCurrentToken();
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}issue/title`,
        {
          data: {
            issueTitleId: titleId,
            projectId: projectId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchTitles();
    } catch (error) {
      console.error("Error deleting title:", error);
    }
  };
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between py-3">
        <h2 className="fw-bold mb-0">Titles</h2>
        <div className="d-flex align-items-center">
          <div className="input-group flex-nowrap mx-2">
            <span
              className="input-group-text bg-transparent"
              id="addon-wrapping"
            >
              <Search />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Title"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
          <button
            className="btn btn-primary flex-shrink-0"
            onClick={() => {
              setShowModal(true);
              fetchParentTitles();
            }}
          >
            Add Title
          </button>
        </div>
      </div>
      <div className="text-end">
        <button
          onClick={async () => {
            setShowFilters(!showFilters);
            if (!showFilters) {
              await fetchParentTitles();
            }
          }}
          aria-controls="filter-collapse"
          aria-expanded={showFilters}
          className="btn mb-2 border-0 px-2 fw-bold text-primary"
        >
          {showFilters ? (
            <>
              <ChevronUp /> Hide
            </>
          ) : (
            <>
              <ChevronDown /> Show
            </>
          )}{" "}
          Filters
        </button>
      </div>
      <Collapse in={showFilters}>
        <div id="filter-collapse" className="border rounded-3 p-4 mb-3">
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <label>Type</label>
                <Select
                  name="typeOptions"
                  options={typeOptions}
                  onChange={setFilterType}
                  value={filterType}
                  isClearable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label>Parent</label>
                <Select
                  name="parentOptions"
                  options={parentOptions}
                  onChange={setFilterParentId}
                  value={filterParentId}
                  isClearable
                />
              </div>
            </div>
            <div className="col-md-4 align-self-end">
              <div className="mb-3">
                <button
                  onClick={clearFilters}
                  className="btn btn-secondary me-2"
                >
                  Clear
                </button>
                <button
                  onClick={() => setApplyFilters(true)}
                  className="btn btn-primary"
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <AddTitleForm
        titles={titles}
        fetchTitles={fetchTitles}
        showModal={showModal}
        setShowModal={setShowModal}
        parentOptions={parentOptions}
      />
      <EditTitleForm
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        title={titleToEdit}
        editTitle={editTitle}
        parentOptions={parentOptions}
        fetchTitles={fetchTitles}
      />
      <div className="table-responsive border rounded p-2">
        {isLoading ? (
          <p>Loading...</p>
        ) : titles.length > 0 ? (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Parent</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {titles.map((title) => (
                  <tr key={title.id}>
                    <td>{title.name}</td>
                    <td>
                      {title.type
                        .split("_")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                    </td>
                    <td>
                      {title.parentIssue ? title.parentIssue.name : "N/A"}
                    </td>
                    <td>
                      <button
                        className="btn btn-link me-2"
                        onClick={() => {
                          setTitleToEdit(title);
                          setShowEditModal(true);
                          fetchParentTitles();
                        }}
                      >
                        <PencilSquare />
                      </button>
                      <button
                        className="btn btn-link text-danger"
                        onClick={() => {
                          deleteTitle(title.id);
                        }}
                      >
                        <Trash3 />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPageSize={totalPageSize}
              onPageChange={handlePageChange}
              totalListCount={totalListCount}
              pageSize={pageSize}
            />
          </>
        ) : (
          <p>No titles found</p>
        )}
      </div>
    </div>
  );
}

export default TitleList;
