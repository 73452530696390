import React, { useState, useEffect, useContext, useCallback } from "react";
import { Tab, Tabs, Modal } from "react-bootstrap";
import AWS from "aws-sdk";
import { cognitoConfig } from "../services/cognito/config";
import {
  getCurrentToken,
  getCurrentUser,
} from "../services/cognito/cognitoAuth";
import ProjectContext from "../ProjectContext";
import { SendFill } from "react-bootstrap-icons";
import axios from "axios";
import ProcessedList from "../components/processed/ProcessedList";
import { useLocation, useNavigate } from "react-router-dom";

function Documents() {
  const { projectId, projectName, userId, roles } = useContext(ProjectContext);
  const [unprocessedFiles, setUnprocessedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [cognitoSub, setCognitoSub] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const { isProcessing, setIsProcessing, currentPage, setCurrentPage } =
    useContext(ProjectContext);
  const countInput = React.createRef();
  const [refreshFiles, setRefreshFiles] = useState(false);
  const [refreshProcessed, setRefreshProcessed] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [initialStatus, setInitialStatus] = useState([]);
  useEffect(() => {
    //console.log("initialStatus", initialStatus);
  }, [initialStatus]);
  const updateInitialStatus = (status) => {
    setInitialStatus(status);
  };
  const handleClear = () => {
    setInitialStatus("");
  };
  const [lastViewedFileId, setLastViewedFileId] = useState(null);
  const location = useLocation();
  const { newUpload } = location.state || {};
  const { currentPage: pageFromState } = location.state || {};
  const [countError, setCountError] = useState(null);
  const navigationState = JSON.parse(localStorage.getItem("navigationState"));
  const fileId = navigationState ? navigationState.fileId : null;
  const statuses = ["PROCESSED", "UPLOADED", "PROCESSING"];
  const navigate = useNavigate();
  useEffect(() => {
    if (pageFromState) {
      setCurrentPage(pageFromState);
    }
    setLastViewedFileId(fileId);
  }, [pageFromState]);
  useEffect(() => {
    if (newUpload) {
      setActiveTab("unprocessed");
    } else {
      setActiveTab("processed");
    }
  }, [newUpload]);
  const fetchUnprocessedFiles = useCallback(async () => {
    let prefix;
    if (roles.includes("Developer")) {
      prefix = `feedbackai/developer/${userId}/unprocessed/`;
    } else if (projectName && projectId) {
      prefix = `feedbackai/${projectName}/unprocessed/`;
    } else {
      console.log("Project name or ID is not available");
      return;
    }

    const s3 = new AWS.S3();
    const params = {
      Bucket: "feedbackaiprod",
      Prefix: prefix,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      if (data.KeyCount === 0) {
        console.log("No files found under the prefix");
      }
      const files = data.Contents.map((file) => {
        const keyParts = file.Key.split("/");
        const date = keyParts[keyParts.length - 2]; // date folder
        const fileName = keyParts[keyParts.length - 1]; // file name
        return `/${date}/${fileName}`;
      });
      setUnprocessedFiles(files);
    } catch (err) {
      console.error("Error listing objects in S3:", err);
    }
  }, [projectName, projectId, refreshFiles, roles, userId]);

  const removeUnprocessedFile = async (fileToRemove) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: "feedbackaiprod",
      Key: `feedbackai/${projectName}/unprocessed${fileToRemove}`,
    };
    if (roles.includes("Developer")) {
      params.Key = `feedbackai/developer/${userId}/unprocessed${fileToRemove}`;
    }

    try {
      await s3.deleteObject(params).promise();
      setUnprocessedFiles(
        unprocessedFiles.filter((file) => file !== fileToRemove)
      );
    } catch (err) {
      console.error("Error deleting object from S3:", err);
    }
  };

  const clearUnprocessedFiles = async () => {
    const deletePromises = unprocessedFiles.map((file) =>
      removeUnprocessedFile(file)
    );
    try {
      handleUserCloseModal();
      await Promise.all(deletePromises);
      setUnprocessedFiles([]);
    } catch (err) {
      handleUserCloseModal();
      console.error("Error deleting all unprocessed files:", err);
    }
  };

  useEffect(() => {
    const fetchTokenAndConfigureAWS = async () => {
      setIsLoading(true);
      try {
        const token = await getCurrentToken();
        setAccessToken(token);

        const userPoolLoginKey = cognitoConfig.userPoolLoginKey;
        const logins = {};
        logins[userPoolLoginKey] = token;

        AWS.config.region = cognitoConfig.region;
        const cognitoParams = {
          IdentityPoolId: cognitoConfig.IdentityPoolIdFileUpload,
          Logins: logins,
        };

        let cognitoidentity = new AWS.CognitoIdentity();
        cognitoidentity.getId(cognitoParams, function (err, data) {
          if (err) {
            console.log(err);
            //showSessionReloadPoup();
          } else {
            let bucketRegion = AWS.config.region;
            AWS.config.update({
              region: bucketRegion,
              credentials: new AWS.CognitoIdentityCredentials(cognitoParams),
            });
            // Clear the cached ID
            AWS.config.credentials.clearCachedId();

            // Refresh or get the credentials
            AWS.config.credentials.get(function (err) {
              if (err) {
                console.log(err);
              } else {
                fetchUnprocessedFiles();
              }
            });
          }
        });
        setIsLoading(false);
      } catch (err) {
        console.log("accessToken is not found", err);
        setIsLoading(false);
      }
    };

    const fetchCognitoSub = async () => {
      try {
        const user = await getCurrentUser();
        setCognitoSub(user.sub);
      } catch (err) {
        console.error(err);
      }
    };

    fetchTokenAndConfigureAWS();
    fetchCognitoSub();
    fetchUnprocessedFiles();
  }, [projectName, projectId, fetchUnprocessedFiles, userId]);

  const handleShowModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleUserShowModal = () => {
    setOpenUserModal(true);
  };
  const handleUserCloseModal = () => {
    setOpenUserModal(false);
  };
  const handleProcess = async () => {
    try {
      const token = await getCurrentToken();
      const count = parseInt(countInput.current.value);
      if (isNaN(count) || count <= 0) {
        setCountError("Please enter a valid number");
        return;
      }
      setIsProcessing(true);
      let requestBody = {
        userId: userId,
        count: parseInt(countInput.current.value),
      };

      if (roles.includes("Developer")) {
        requestBody.isDeveloper = true;
      } else {
        requestBody.projectName = projectName;
      }
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}file-upload/process`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          handleCloseModal();
          setRefreshFiles((prevState) => !prevState);
          localStorage.setItem("selectedStatusList", JSON.stringify(statuses));
          // setInitialStatus([
          //   { value: "PROCESSED", label: "PROCESSED" },
          //   { value: "UPLOADED", label: "UPLOADED" },
          //   { value: "PROCESSING", label: "PROCESSING" },
          // ]);

          setIsProcessing(true);
          setActiveTab("processed");
          setRefreshProcessed((prevState) => !prevState);
          navigate(location.pathname, { state: { newUpload: false } });
        });
    } catch (err) {
      console.error("Error processing files:", err);
    }
  };
  const handleInputChange = () => {
    const count = parseInt(countInput.current.value);
    if (!isNaN(count) && count > 0) {
      setCountError(null);
    }
  };
  const refreshProcessedFiles = () => {
    setRefreshProcessed((prevState) => !prevState);
  };
  return (
    <div className="container-fluid flex-grow-1">
      <h2 className="fw-bold mb-4">Documents</h2>
      <Tabs
        activeKey={activeTab}
        onSelect={(key) => {
          setActiveTab(key);
          key === "processed" && refreshProcessedFiles();
        }}
      >
        <Tab eventKey="processed" title="Processed">
          <div className="d-flex align-items-center justify-content-between py-3">
            <ProcessedList
              refresh={refreshProcessed}
              initialStatus={initialStatus}
              onClear={handleClear}
              lastViewedFileId={lastViewedFileId}
              updateInitialStatus={updateInitialStatus}
            />
          </div>
        </Tab>
        <Tab eventKey="unprocessed" title="Unprocessed">
          <div className="py-3">
            <div className="d-flex align-items-center justify-content-end mb-3">
              {unprocessedFiles.length !== 0 && (
                <p className="m-0 me-3 fw-bold">
                  Number of uploaded files: {unprocessedFiles.length}
                </p>
              )}
              <button
                className="btn btn-sm btn-primary px-3"
                onClick={handleShowModal}
                disabled={unprocessedFiles.length === 0}
              >
                <SendFill /> Process
              </button>
              <button
                className="btn btn-sm btn-secondary px-3 ms-2"
                onClick={handleUserShowModal}
                disabled={unprocessedFiles.length === 0}
              >
                Clear All
              </button>
            </div>

            <Modal show={openUserModal} onHide={handleUserCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Confimation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <label className="form-label">
                    Are you sure to clear all files?
                  </label>
                </div>
                <div>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={clearUnprocessedFiles}
                  >
                    Clear All
                  </button>
                  <button
                    className="btn btn-secondary mt-3 ms-2"
                    onClick={handleUserCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={openModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Process Documents</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <label className="form-label">
                    Number of documents to process
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    ref={countInput}
                    onChange={handleInputChange}
                  />
                  {countError && (
                    <div className="text-danger">{countError}</div>
                  )}
                </div>
                <div>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleProcess}
                  >
                    Process
                  </button>
                  <button
                    className="btn btn-secondary mt-3 ms-2"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Body>
            </Modal>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <ul className="list-unstyled unprocess-list custom-scroll">
                {unprocessedFiles.length === 0 ? (
                  <li>No files found.</li>
                ) : (
                  unprocessedFiles.map((file) => (
                    <li
                      className="d-inline-block p-2 border rounded-pill m-2"
                      key={file}
                    >
                      {file}
                      <button
                        className="btn px-1 btn-sm ms-1 border-0 py-0"
                        onClick={() => removeUnprocessedFile(file)}
                      >
                        X
                      </button>
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Documents;
