import React, { useState, useEffect, useContext } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  fetchUserRoles,
  isLoggedIn,
  getCurrentUser,
  getCurrentToken,
} from "../services/cognito/cognitoAuth";
import MainLayout from "../layout/Layout";
import Home from "../pages/Home";
import Users from "../pages/Users";
import FileList from "../pages/FileList";
import Projects from "../pages/Projects";
import ProjectContext from "../ProjectContext";
import axios from "axios";
import FileViewer from "../pages/FileViewer";
import ImportDocuments from "../pages/ImportDocuments";
import Documents from "../pages/Documents";
import FileLog from "../pages/FileLog";
import Dashboard from "../pages/Dashboard";
import TitleList from "../pages/Titles";
import AddIssue from "./issues/AddIssues";
import IssueList from "../pages/IssueList";
import EditIssue from "./issues/EditIssue";

function AuthenticatedRoutes() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const {
    setProjectId,
    roles,
    setRoles,
    setProjectName,
    userId,
    setUserId,
  } = useContext(ProjectContext);
  const [cognitoSub, setCognitoSub] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const location = useLocation();

  const fetchUserAndProjectInfo = async (cognitoSub, token) => {
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      const userResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${cognitoSub}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const lastAccessedProjectName = userResponse.data.currentProject.name;
      const lastAccessedProjectId = userResponse.data.currentProject.id;
      setProjectId(lastAccessedProjectId);
      setProjectName(lastAccessedProjectName);
      const userId = userResponse.data.id;
      setUserId(userId);
      const userRoles = userResponse.data.userGroups;
      setRoles(userRoles);
      // Redirection logic
      if (firstLoad && location.pathname === "/login") {
        const roleRedirects = {
          SuperAdmin: "/users",
          Developer: "/import-documents",
          Reviewer: "/dashboard",
          ProjectAdmin: "/dashboard",
        };

        const userRole = Object.keys(roleRedirects).find((role) =>
          userRoles.includes(role)
        );

        if (userRole) {
          navigate(roleRedirects[userRole], { replace: true });
        } else {
          navigate("/dashboard", { replace: true }); // Fallback to dashboard
        }

        setFirstLoad(false); // Set firstLoad to false after the first redirection
      }
    } catch (error) {
      console.error("Error fetching last accessed project:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeAuthentication = async () => {
      try {
        const loggedIn = await isLoggedIn();
        setAuthenticated(loggedIn);

        if (loggedIn) {
          const user = await getCurrentUser();
          const token = await getCurrentToken();
          setCognitoSub(user.sub);
          setAccessToken(token);

          await fetchUserAndProjectInfo(user.sub, token);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error initializing authentication:", error);
        setLoading(false);
      }
    };

    initializeAuthentication();
  }, []);
  // Show loading screen while data is being fetched
  if (loading) {
    return <div>Loading...</div>; // Show loading until roles and authentication are confirmed
  }

  // Redirect to login if not authenticated
  if (!authenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path="/dashboard"
          element={
            <RoleProtectedRoute allowedRoles={["Reviewer", "ProjectAdmin"]}>
              <Dashboard />
            </RoleProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <RoleProtectedRoute allowedRoles={["SuperAdmin", "ProjectAdmin"]}>
              <Users />
            </RoleProtectedRoute>
          }
        />

        <Route path="/files" element={<FileList />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/file-viewer" element={<FileViewer />} />
        <Route
          path="/import-documents"
          element={
            <RoleProtectedRoute allowedRoles={["Developer", "ProjectAdmin"]}>
              <ImportDocuments />
            </RoleProtectedRoute>
          }
        />
        <Route path="/documents" element={<Documents />} />
        <Route path="/action-log" element={<FileLog />} />
        <Route
          path="/title-list"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin"]}>
              <TitleList />
            </RoleProtectedRoute>
          }
        />
        <Route path="/issues" element={<IssueList />} />
        <Route path="/add-issue" element={<AddIssue />} />
        <Route path="/edit-issue/:id" element={<EditIssue />} />
      </Route>

      {/* Fallback */}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
}
// Role-protected route component
const RoleProtectedRoute = ({ allowedRoles, children }) => {
  const { roles: userRoles } = useContext(ProjectContext); // Access user roles from ProjectContext
  const navigate = useNavigate();

  // Check if the user's roles have been loaded
  if (!userRoles || userRoles.length === 0) {
    // If roles aren't loaded, you can show a loading spinner or block access
    return <div>Loading...</div>;
  }
  // Check if the user has access to the current route
  const hasAccess = allowedRoles.some((role) => userRoles.includes(role));
  if (!hasAccess) {
    // Find the highest priority role and redirect
    const roleRedirects = {
      SuperAdmin: "/users",
      Developer: "/import-documents",
      Reviewer: "/dashboard",
      ProjectAdmin: "/dashboard",
    };

    const userRole = Object.keys(roleRedirects).find((role) =>
      userRoles.includes(role)
    );

    if (userRole) {
      navigate(roleRedirects[userRole], { replace: true });
    } else {
      navigate("/dashboard", { replace: true }); // Default fallback
    }

    return null; // Prevent rendering of the current page
  }

  return children;
};
export default AuthenticatedRoutes;
