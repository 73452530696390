import React, { useState, useEffect, useContext } from "react";
import AWS from "aws-sdk"; // Import the AWS SDK
import { cognitoConfig } from "../services/cognito/config";
import {
  getCurrentToken,
  getCurrentUser,
} from "../services/cognito/cognitoAuth";
import axios from "axios";
import loaderImg from "../assets/images/loader.gif";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectContext from "../ProjectContext";
import MultiFileUpload from "../components/MultiFileUpload";

function ImportDocuments() {
  const [files, setFiles] = useState([]); // Store an array of files
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [uploadError, setUploadError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [cognitoSub, setCognitoSub] = useState(null);
  const [fileLogPosted, setFileLogPosted] = useState(false);
  const [fileLogError, setFileLogError] = useState(null);
  const location = useLocation();
  //const projectId = location.state?.projectId; // Get the projectId from the location state
  const { projectId, projectName, roles, userId } = useContext(ProjectContext);
  //const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const currentDate = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(currentDate); // Set the default date to today
  const [newUpload, setNewUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  console.log("projectName", projectName);
  useEffect(() => {
    const fetchTokenAndConfigureAWS = async () => {
      setIsLoading(true);
      try {
        const token = await getCurrentToken();
        setAccessToken(token);

        const userPoolLoginKey = cognitoConfig.userPoolLoginKey;
        const logins = {};
        logins[userPoolLoginKey] = token;

        AWS.config.region = cognitoConfig.region;
        const cognitoParams = {
          IdentityPoolId: cognitoConfig.IdentityPoolIdFileUpload,
          Logins: logins,
        };

        let cognitoidentity = new AWS.CognitoIdentity();
        cognitoidentity.getId(cognitoParams, function (err, data) {
          if (err) {
            console.log(err);
            //showSessionReloadPoup();
          } else {
            let bucketRegion = AWS.config.region;
            AWS.config.update({
              region: bucketRegion,
              credentials: new AWS.CognitoIdentityCredentials(cognitoParams),
            });
            // Clear the cached ID
            AWS.config.credentials.clearCachedId();

            // Refresh or get the credentials
            AWS.config.credentials.get(function (err) {
              if (err) {
                console.log(err);
              } else {
                console.log("Successfully refreshed credentials");
              }
            });
          }
        });
        setIsLoading(false);
      } catch (err) {
        console.log("accessToken is not found", err);
        setIsLoading(false);
      }
    };

    fetchTokenAndConfigureAWS();

    const fetchCognitoSub = async () => {
      try {
        const user = await getCurrentUser();
        setCognitoSub(user.sub);
      } catch (err) {
        console.error(err);
      }
    };

    fetchCognitoSub();
  }, []);

  const handleFileSelect = (selectedFile) => {
    setFiles(selectedFile);
    setUploadedCount(0);
  };

  const handleFileUpload = async () => {
    if (files.length === 0) {
      alert("Please choose a file to upload first.");
      return;
    }
    if (!projectId && !roles.includes("Developer")) {
      alert("Please select a project before uploading.");
      return;
    }
    if (isLoading) {
      return;
    }
    setUploadStatus("uploading");

    // Create S3 object
    const s3 = new AWS.S3({
      params: { Bucket: "feedbackaiprod" },
    });
    let completedFiles = 0;
    for (const file of files) {
      let fileNameInS3 = `feedbackai/${projectName}/unprocessed/${selectedDate}/${file.name}`;
      if (roles.includes("Developer")) {
        fileNameInS3 = `feedbackai/developer/${userId}/unprocessed/${selectedDate}/${file.name}`;
      }

      // Use S3 ManagedUpload class as it supports multipart uploads
      const options = {
        partSize: 5 * 1024 * 1024, // 5 MB
        queueSize: 10,
      };

      const upload = new AWS.S3.ManagedUpload(
        {
          params: {
            Key: fileNameInS3,
            Bucket: "feedbackaiprod",
            Body: file,
            ACL: "private",
          },
        },
        options
      );

      upload.on("httpUploadProgress", (evt) => {
        const fileProgress = (evt.loaded / evt.total) * 100;
        const overallProgress =
          ((completedFiles + fileProgress / 100) / files.length) * 100;
        setUploadProgress(Math.round(overallProgress));
      });

      try {
        await upload.promise();
        completedFiles += 1;
        setUploadedCount(completedFiles);
      } catch (err) {
        console.error(err);
        return;
      }
    }
    setUploadStatus("completed");
    setNewUpload(true);
    // navigate("/documents");
  };
  useEffect(() => {
    if (newUpload) {
      navigate("/documents", { state: { newUpload: true } });
      setNewUpload(false);
    }
  }, [newUpload, navigate]);
  const handleUploadNewClick = () => {
    setFiles([]);
    setUploadStatus("idle");
    setShowSuccess(false);
    setFileLogError(null);
    setUploadedCount(0);
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  return (
    <section className="hero-content my-auto">
      <div className="container">
        <div className="text-center font-fjalla">
          {/* <img src={logo} alt="logo" /> */}
          {projectName && !roles.includes("Developer") && (
            <>
              <p className="text-muted mb-2">Project</p>
              <h3 className="text-orange">{projectName}</h3>
            </>
          )}
        </div>
        <div className="my-4 position-relative">
          {!projectId && !isLoading && !roles.includes("Developer") && (
            <div className="overlay position-absolute top-0 start-0 w-100 h-100"></div>
          )}
          {uploadStatus === "idle" && (
            <MultiFileUpload
              onFileUpload={handleFileUpload}
              handleFileSelect={handleFileSelect}
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
              fileCount={files.length} // Pass file count
              uploadedCount={uploadedCount} // Pass uploaded count
            />
          )}
          {uploadStatus === "uploading" && !fileLogError && (
            <>
            <p className="text-center mb-2">{uploadedCount}/{files.length} files uploaded</p>
            <div className="progress upload-progress mx-auto">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {`${uploadProgress}%`}
              </div>
            </div>
            </>
          )}
          {fileLogError && (
            <div className="text-center">
              <div className="alert alert-danger">{fileLogError?.message}</div>
              <button
                className="btn btn-outline-primary"
                onClick={handleUploadNewClick}
              >
                Upload New
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ImportDocuments;
