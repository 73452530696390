import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select, { components } from "react-select";
import ProjectContext from "../../ProjectContext";

function EditProject({ project, handleClose, triggerRefresh }) {
  const [projectName, setProjectName] = useState(project.name);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [pageSize, setPageSize] = useState();
  const [projectNameError, setProjectNameError] = useState("");
  const { roles } = useContext(ProjectContext);

  useEffect(() => {
    // Fetch total number of users when component mounts
    const fetchTotalUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}user/list`,
          {
            headers: {
              Authorization: `Bearer ${await getCurrentToken()}`,
            },
            params: {
              page: 1,
              size: 1, // small size for initial request
              search: "",
              status: "ACTIVE",
              sortColumn: "id",
              sortOrder: "DESC",
              group: "",
            },
          }
        );
        if (response.status === 200) {
          setPageSize(response.data.pageInfo.totalElements);
        } else {
          console.error("Error fetching total number of users:", response);
        }
      } catch (error) {
        console.error("Error fetching total number of users:", error);
      }
    };

    fetchTotalUsers();
    fetchProjectUsers();
  }, []);

  useEffect(() => {
    // Fetch users when pageSize changes
    const fetchUsers = async () => {

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}user/list`,
          {
            headers: {
              Authorization: `Bearer ${await getCurrentToken()}`,
            },
            params: {
              page: 1,
              size: "", // use pageSize
              search: "",
              status: "ACTIVE",
              sortColumn: "id",
              sortOrder: "DESC",
              group: "",
            },
          }
        );
        if (response.status === 200) {
          setUsers(
            response.data.content
            .filter(user => !user.userGroups.includes("SuperAdmin"))
            .map((user) => ({
              value: user.id,
              label:
                user.firstName +
                " " +
                user.lastName +
                " (" +
                user.userGroups +
                ")",
                isDisabled: roles.includes("ProjectAdmin") && user.userGroups.includes("ProjectAdmin"),
            }))
          );
        } else {
          console.error("Error fetching users:", response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [pageSize]); // re-run when pageSize changes
  const fetchProjectUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "",
            projectId: project.id,
          },
        }
      );
      if (response.status === 200) {
        setSelectedUsers(
          response.data.content.map((user) => ({
            value: user.id,
            label:
              user.firstName +
              " " +
              user.lastName +
              " (" +
              user.userGroups +
              ")",
              isDisabled: roles.includes("ProjectAdmin") && user.userGroups.includes("ProjectAdmin"),
          }))
        );
      } else {
        console.error("Error fetching project users:", response);
      }
    } catch (error) {
      console.error("Error fetching project users:", error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!projectName) {
      setProjectNameError("Project Name is required");
      return;
    }
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}project`,
        {
          id: project.id,
          name: projectName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        await updateProjectUsers();
        triggerRefresh(); // refresh the project list
        handleClose(); // close the modal
      } else {
        console.error("Error updating project:", response);
      }
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };
  const updateProjectUsers = async () => {
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}project/users`,
        {
          projectId: project.id,
          userIds: selectedUsers.map((user) => user.value),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status !== 200) {
        console.error("Error updating project users:", response);
      }
    } catch (error) {
      console.error("Error updating project users:", error);
    }
  };
  const MultiValueRemove = (props) => {
    if (props.data.isDisabled) {
      return null; // Don't return the remove option for disabled options
    }
    return <components.MultiValueRemove {...props} />;
  };
  return (
    <div>
      <Modal.Header>
        <Modal.Title>Edit Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Project Name</label>
            <input
              type="text"
              className="form-control"
              name="projectName"
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
                setProjectNameError("");
              }} 
              disabled={!roles.includes("SuperAdmin")} // disable if role is not SuperAdmin
            />
            {projectNameError && (
              <div className="text-danger">{projectNameError}</div>
            )}
          </div>
          <div className="mb-3">
            <label>Users</label>
            <Select
              isMulti
              name="users"
              options={users}
              value={selectedUsers}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedUsers}
              components={{ MultiValueRemove }}
            />
          </div>
          <div className="mb-3 text-end">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={handleClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </Modal.Body>
    </div>
  );
}

export default EditProject;
