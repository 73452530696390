import React, { useState, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { getCurrentToken } from '../../services/cognito/cognitoAuth';
import ProjectContext from '../../ProjectContext';

function AddTitleForm({ titles, fetchTitles, showModal, setShowModal, parentOptions }) {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [parent, setParent] = useState('');
  const { projectName } = useContext(ProjectContext);
  const [nameError, setNameError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [parentError, setParentError] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameError(''); // Clear error message when user starts typing
  };
  const handleTypeChange = (selectedOption) => {
    setType(selectedOption.value);
    setTypeError(''); // Clear error message when user selects an option
  };
  const handleParentChange = (selectedOption) => {
    setParent(selectedOption.value);
    setParentError(''); // Clear error message when user selects an option
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check if fields are empty
    if (!name) setNameError('Name is required');
    if (!type) setTypeError('Type is required');
    if (type === 'subsection' && !parent) setParentError('Parent is required');
    if (name && type && (type !== 'subsection' || parent)) {
      try {
        const token = await getCurrentToken();
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}issue/title`,
          {
            name,
            type,
            projectName: projectName,
            parentId: parent,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchTitles(); // refresh the title list
        setShowModal(false); // close the modal
        resetForm(); // reset the form
      } catch (error) {
        console.error('Error adding title:', error);
      }
    }
  };
  const typeOptions = [
    { value: 'section', label: 'Section' },
    { value: 'subsection', label: 'Subsection' },
    { value: 'Issue nature', label: 'Issue Nature' },
    { value: 'Issue Type', label: 'Issue Type'},
    { value: 'Template change type', label: 'Template Change Type'},
    { value: 'Tag', label: 'Tag'},
  ];
  
  const resetForm = () => {
    setName('');
    setType('');
    setParent('');
  };
  const handleClose = () => {
    setShowModal(false);
    resetForm();
  };
  
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Title</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="titleName" className="form-label">Title Name</label>
            <input type="text" className="form-control" id="titleName" value={name} onChange={handleNameChange} />
            {nameError && <div className="text-danger">{nameError}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="titleType" className="form-label">Type</label>
            <Select options={typeOptions} isSearchable={false} onChange={handleTypeChange} />
            {typeError && <div className="text-danger">{typeError}</div>}
          </div>
          {type === 'subsection' && (
            <div className="mb-3">
              <label htmlFor="titleParent" className="form-label">Parent</label>
              <Select options={parentOptions} isSearchable={false} onChange={handleParentChange} />
              {parentError && <div className="text-danger">{parentError}</div>}
            </div>
          )}
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddTitleForm;