import React from "react";

function Pagination({
  currentPage,
  totalPageSize,
  onPageChange,
  totalListCount,
  pageSize,
}) {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPageSize) {
      onPageChange(currentPage + 1);
    }
  };

  let startPage, endPage;
  if (totalPageSize <= 5) {
    startPage = 1;
    endPage = totalPageSize;
  } else {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= totalPageSize) {
      startPage = totalPageSize - 4;
      endPage = totalPageSize;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  const pageItems = [];
  for (let i = startPage; i <= endPage; i++) {
    pageItems.push(
      <li className="page-item" key={i}>
        <button
          className={`page-link ${currentPage === i ? "active" : ""}`}
          onClick={() => onPageChange(i)}
        >
          {i}
        </button>
      </li>
    );
  }

  const startIndex = (currentPage - 1) * pageSize + 1;
  const endIndex = Math.min(startIndex + pageSize - 1, totalListCount);

  return (
    <nav aria-label="Page navigation" className="d-flex align-items-center">
      {totalListCount && (
        <p className="text-muted m-0">
          Showing{" "}
          <span className="text-dark fw-bold">
            {startIndex}-{endIndex}
          </span>{" "}
          of <span className="text-dark fw-bold">{totalListCount}</span> entries
        </p>
      )}
      <ul className="pagination justify-content-end m-0 ms-auto">
        <li className="page-item">
          <button
            className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </li>
        {totalPageSize > 5 && currentPage > 3 && (
          <>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => onPageChange(1)}
              >
                1
              </button>
            </li>
            <li className="page-item">
              <span className="page-link">...</span>
            </li>
          </>
        )}
        {pageItems}
        {totalPageSize > 5 && currentPage + 2 < totalPageSize && (
          <>
            <li className="page-item">
              <span className="page-link">...</span>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => onPageChange(totalPageSize)}
              >
                {totalPageSize}
              </button>
            </li>
          </>
        )}
        <li className="page-item">
          <button
            className={`page-link ${
              currentPage === totalPageSize ? "disabled" : ""
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPageSize}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;