  // export const cognitoConfig = {
  //   UserPoolId: 'ap-south-1_bC8JI7YdE',
  //   ClientId: '37c2vh83p8rp50mcjr2eedom8c',
  //   region: 'ap-south-1',
  //   IdentityPoolIdFileUpload: 'ap-south-1:aa3a0fd3-e6cd-4e52-baca-30ce6f09351a',
  //   userPoolLoginKey: 'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_bC8JI7YdE',
  // }
  export const cognitoConfig = {
    UserPoolId: 'ap-south-1_BPoZMTpRj',
    ClientId: '7tl11js6hhc8sfinohrms5ivg8',
    region: 'ap-south-1',
    IdentityPoolIdFileUpload: 'ap-south-1:230f11cd-9be1-4806-a2bf-58af8702ca4d',
    userPoolLoginKey: 'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_BPoZMTpRj',
  }