import React, { useEffect, useState, useContext } from 'react';
import { formatDate } from '../../utils';
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import ProjectContext from '../../ProjectContext';

function LogsTable({id}) {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { projectName } = useContext(ProjectContext);


  useEffect(() => {
    fetchLogs();
  }, []);
  const fetchLogs = async () => {
    setIsLoading(true);
    try {
      const token = await getCurrentToken();
      
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}file-upload/file-action-log/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            sortColumn: "",
            sortOrder: "DESC",
            startDate: "",
            endDate: "",
            action: "",
            projectName: projectName,
            search: "",
            fileId: id,
          },
        }
      );

      setLogs(response.data.content);
      setIsLoading(false);
    } catch (err) {
      console.log("Error fetching logs: ", err);
      setIsLoading(false);
    }
  };
  if (!logs || logs.length === 0) {
    return <p>No logs found</p>;
  }

  return (
    <div className="table-responsive overflow-y-auto" style={{maxHeight: "500px"}}>
    <table className="table table-borderless">
      <thead>
        <tr>
          <th>User</th>
          <th>Action</th>
          <th width="30%">File</th>
          <th>Timestamp</th>
          <th>Message</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log, index) => (
          <tr key={index}>
            <td>{log.userName}</td>
            <td>{log.action}</td>
            <td>{log.fileName}</td>
            <td>{log.createdAt ? formatDate(log.createdAt) : ""}</td>
            <td>{log.message}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
}

export default LogsTable;