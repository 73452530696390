// Desc: Utility functions
export function formatDate(dateString) {
  // Create a new Date object from the API date string
  let date = new Date(dateString);

  // Adjust the date to the local timezone
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  // Convert the date to a string using the browser's time zone
  return date.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true
  });
}

export function formatDateWithoutTime(dateString) {
  // Create a new Date object from the API date string
  let date = new Date(dateString);

  // Convert the date to a string using the browser's time zone
  return date.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
}