import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  completeNewPasswordChallenge,
  changePassword,
  fetchUserRoles, 
  signOut,
} from "../../services/cognito/cognitoAuth";
import AuthContext from "../auth/AuthContext";

function ChangePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [roles, setRoles] = useState([]);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { setAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (newPassword !== confirmPassword) {
        throw new Error("Passwords do not match.");
      }
      let cognitoUser = window.currentUser;

      if (window.newPasswordRequired) {
        //debugger;
        await completeNewPasswordChallenge(cognitoUser, newPassword);

        window.newPasswordRequired = false;
        // if (roles.includes("SuperAdmin")) {
        //   navigate("/users");
        // } else {
        //   navigate("/home");
        // }
        setIsPasswordChanged(true);
      } else {
        await changePassword(oldPassword, newPassword);
        navigate("/home");
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };
  useEffect(() => {
    if (!window.newPasswordRequired) {
      fetchUserRoles().then((fetchedRoles) => {
        setRoles(fetchedRoles); // set the roles in the state
      });
    }
  }, []);
  const handleSignOut = async () => {
    await signOut();
    navigate("/login");
    setAuthenticated(false);
  };
  return (
    <>
      <h2 className="text-center mb-4 fw-bold">Change Password</h2>
      {isPasswordChanged && (
        <div className="text-success text-center mt-2">
          Password changed successfully. <Link  onClick={handleSignOut}>Login again</Link>
        </div>
      )}
      {!isPasswordChanged && 
      <form onSubmit={handleSubmit}>
        {!window.newPasswordRequired && (
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control rounded-0"
              id="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              placeholder="Old Password"
            />
            <label htmlFor="oldPassword">Old Password</label>
          </div>
        )}
        <div className="form-floating mb-3">
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="form-control rounded-0"
            id="newPassword"
            placeholder="New Password"
          />
          <label htmlFor="newPassword">New Password</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-control rounded-0"
            id="confirmPassword"
            placeholder="Confirm Password"
          />
          <label htmlFor="confirmPassword">Confirm Password</label>
        </div>
        <button className="btn btn-primary w-100" type="submit">
          Change Password
        </button>
        {error && <div className="text-danger text-center mt-2">{error}</div>}
        {!window.newPasswordRequired && (
          <div className="text-center mt-2">
            {roles.includes("SuperAdmin") ? (
              <Link to="/users">Back to users</Link>
            ) : (
              <Link to="/home">Back to home</Link>
            )}
          </div>
        )}
      </form>
      }
    </>
  );
}

export default ChangePassword;
