import { createContext } from 'react';

const ProjectContext = createContext({
  projectId: null,
  setProjectId: () => {},
  roles: [],
  setRoles: () => {},
  projectName: null,
  setProjectName: () => {},
  userId: null,
  setUserId: () => {},
  isProcessing: false,
  setIsProcessing: () => {},
  refreshFiles: false,
  setRefreshFiles: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
});

export default ProjectContext;