import React, { } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import LeftMenu from "../components/common/LeftMenu";

function MainLayout() {

  return (
    <div className="d-flex w-100 h-100">
      <LeftMenu />
      <div className="content-page flex-grow-1 px-3 w-100">
        <div className="d-flex flex-column min-vh-100 pb-4">
          <Header />
          <Outlet />
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
