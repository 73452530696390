import React, { useState } from "react";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../services/cognito/cognitoAuth";

function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await forgotPassword(username);
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  if (success) {
    return (
      <div>
        <h2 className="fw-bold text-center">Reset password</h2>
        <p>
          Check your email for the confirmation code to reset your password.
        </p>
        <Link to="/reset-password">Reset Password</Link>
      </div>
    );
  }
  return (
    <div>
      <h3 className="fw-bold text-center mb-4">Forgot Password</h3>
      <p>
        Please enter your username and we will send you an email with a
        verification code to reset your password.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control rounded-0"
            placeholder="Please enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor="floatingInput">Please enter username</label>
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary w-100 mb-2">
            Send Verification Code
          </button>
          {error && <p className="text-danger">{error}</p>}
          <Link to="/login" className="text-blue">
            Back to Login
          </Link>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
