import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";

import { PencilSquare, EyeFill } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import UsersProjectList from "./UsersProjectList";
import Pagination from "../Pagination";
import ProjectContext from "../../ProjectContext";
import { set } from "immutable";

function UserList({
  onEditUser,
  activeTab,
  searchQuery,
  currentPage,
  setCurrentPage,
  refreshKey,
}) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const { userId, roles } = useContext(ProjectContext);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const pageSize = 10;
  const [showReinviteModal, setShowReinviteModal] = useState(false);
const [reinviteEmail, setReinviteEmail] = useState('');
const [message, setMessage] = useState(null);
const [messageError, setMessageError] = useState(null);
const [totalListCount, setTotalListCount] = useState(0);
  useEffect(() => {
    fetchUsers();
  }, [currentPage, searchQuery, activeTab, refreshKey]);
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            size: pageSize,
            search: searchQuery,
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "",
          },
        }
      );

      const jsonData = response.data;
      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 10 && pageInfo.totalElements % 10 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
      const usersData = jsonData.content;
      if (Array.isArray(usersData)) {
        setUsers(usersData);
        setLoading(false);
      } else {
        console.error("Invalid data format: content is not an array");
      }
    } catch (error) {
      console.error("Error fetching admin users:", error);
    }
  };
  const getStatusClassName = (status) => {
    return status === "ACTIVE"
      ? "badge text-bg-success"
      : "badge text-bg-secondary";
  };
  const handleActivateUser = async (userId) => {
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}user/status`,
        {
          id: userId,
          status: "ACTIVE",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Refresh user data after activation
      fetchUsers();
    } catch (error) {
      console.error("Error activating user:", error);
    }
  };

  const handleDeactivateUser = async (userId) => {
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}user/status`,
        {
          id: userId,
          status: "INACTIVE",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Refresh user data after deactivation
      fetchUsers();
    } catch (error) {
      console.error("Error deactivating user:", error);
    }
  };

  const handleShowProjects = (userId, userName) => {
    handleShow();
    setSelectedUserId(userId);
    setSelectedUserName(userName);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleReinvite = async () => {
    const token = await getCurrentToken();
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}user/reinvite`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userName: reinviteEmail,
        },
      });
      setMessage('Reinvitation sent');
      setTimeout(() => setMessage(null), 3000);
    } catch (error) {
      console.error('Error reinviting user:', error);
      setMessageError('Error reinviting user');
      setTimeout(() => setMessage(null), 3000);
    }
  };
  
  
  const handleReinviteFormSubmit = (event) => {
    event.preventDefault();
    handleReinvite();
    setShowReinviteModal(false);
  };
  return (
    <div className="table-responsive border rounded p-2">
      {message && <p className="alert alert-success text-center fs-6 mt-3">{message}</p>}
      {messageError && <p className="alert alert-danger text-center fs-6 mt-3">{messageError}</p>}
      {loading ? (
        <p className="text-center fs-5 mt-3">Loading...</p>
      ) : (
        <>
          {users.length === 0 ? (
            <p className="text-center fs-5 mt-3">No users found</p>
          ) : (
            <table className="table table-borderless vertical-align-middle">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Projects</th>
                  {(roles.includes("SuperAdmin") ||
                    roles.includes("ProjectAdmin")) && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.userGroups.join(", ")}</td>
                    <td>
                      <span className={getStatusClassName(user.status)}>
                        {user.status}
                      </span>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary border-0 px-2 me-2"
                        onClick={() =>
                          handleShowProjects(
                            user.id,
                            `${user.firstName} ${user.lastName}`
                          )
                        }
                        disabled={!user.projects || user.projects.length === 0}
                      >
                        <EyeFill size={16} />
                      </button>
                    </td>

                    <td>
                      {(roles.includes("SuperAdmin") ||
                        roles.includes("ProjectAdmin")) && (
                        <Link
                          onClick={() => onEditUser(user)}
                          className="btn btn-sm btn-primary border-0 px-2 me-2"
                        >
                          <PencilSquare size={18} />
                        </Link>
                      )}
                      {roles.includes("SuperAdmin") && (
                        <>
                          {user.status === "ACTIVE" ? (
                            <button
                              className="btn btn-sm btn-danger px-3"
                              onClick={() => handleDeactivateUser(user.id)}
                            >
                              Inactivate
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-success px-3"
                              onClick={() => handleActivateUser(user.id)}
                            >
                              Activate
                            </button>
                          )}
                          <button
                            className="btn btn-sm btn-outline-primary px-3 ms-2"
                            onClick={() => {
                              setShowReinviteModal(true);
                              setReinviteEmail(user.email);
                            }}
                          >
                            Reinvite
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <Modal show={show} onHide={handleClose}>
            <UsersProjectList
              userId={selectedUserId}
              userName={selectedUserName}
            />
          </Modal>
          <Modal
            show={showReinviteModal}
            onHide={() => setShowReinviteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Reinvite User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleReinviteFormSubmit}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={reinviteEmail}
                    onChange={(e) => setReinviteEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Reinvite
                </button>
              </form>
            </Modal.Body>
          </Modal>
          <Pagination
            currentPage={currentPage}
            totalPageSize={totalPageSize}
            onPageChange={handlePageChange}
            totalListCount={totalListCount}
            pageSize={pageSize}
          />
        </>
      )}
    </div>
  );
}

export default UserList;
