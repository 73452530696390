import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import axios from "axios";
import { getCurrentToken, getCurrentUser } from "../cognito/cognitoAuth";

const firebaseConfig = {
  apiKey: "AIzaSyAkNi_KLFm_E04Sro5gtBAIcdLLJkDnsYc",
  authDomain: "prodexdocpro.firebaseapp.com",
  projectId: "prodexdocpro",
  storageBucket: "prodexdocpro.appspot.com",
  messagingSenderId: "649738462225",
  appId: "1:649738462225:web:9d77eb0ce63c6da1e43db0",
  //measurementId: "G-NV1DG2DLJM"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Function to request permission for notifications and get the token
const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Get the token
      const token = await getToken(messaging);
      return token;
    } else {
      console.error("Notification permission denied");
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};

// Function to listen for incoming messages
const listenForMessages = () => {
  onMessage(messaging, (payload) => {
    //console.log("Message received:", payload);
    // Handle the incoming message here
  });
};

// Function to send the FCM token and Cognito Sub to the backend
const registerToken = async (fcmToken, cognitoSub) => {
  try {
    // console.log(`Registering token ${fcmToken} for user ${cognitoSub}`);
    const token = await getCurrentToken();
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}fcm/fcm-register`,
      {
        fcmToken: fcmToken,
        cognitoSub: cognitoSub,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //console.log("Token registered successfully");
  } catch (error) {
    console.error("Error registering token:", error);
    // console.error(`Error registering token ${fcmToken} for user ${cognitoSub}: ${error}`);
  }
};

// Function to remove the FCM token from the backend
const deRegisterToken = async (fcmToken, cognitoSub) => {
  try {
    const token = await getCurrentToken();
    await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}fcm/fcm-de-register`,
      {
        fcmToken: fcmToken,
        cognitoSub: cognitoSub,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //console.log("Token removed successfully");
  } catch (error) {
    console.error("Error removing token:", error);
  }
};

export {
  requestNotificationPermission,
  listenForMessages,
  registerToken,
  deRegisterToken,
};