import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select from "react-select";

function AddProject({ handleClose, triggerRefresh }) {
  const [projectName, setProjectName] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [pageSize, setPageSize] = useState();
  const [projectNameError, setProjectNameError] = useState("");

  useEffect(() => {
    // Fetch total number of users when component mounts
    const fetchTotalUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}user/list`,
          {
            headers: {
              Authorization: `Bearer ${await getCurrentToken()}`,
            },
            params: {
              page: 1,
              size: 1, // small size for initial request
              search: "",
              status: "ACTIVE",
              sortColumn: "id",
              sortOrder: "DESC",
              group: "",
            },
          }
        );
        if (response.status === 200) {
          setPageSize(response.data.pageInfo.totalElements);
        } else {
          console.error("Error fetching total number of users:", response);
        }
      } catch (error) {
        console.error("Error fetching total number of users:", error);
      }
    };

    fetchTotalUsers();
  }, []);

  useEffect(() => {
    // Fetch users when pageSize changes
    const fetchUsers = async () => {
      if (!pageSize) return; // if pageSize is not set, do nothing

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}user/list`,
          {
            headers: {
              Authorization: `Bearer ${await getCurrentToken()}`,
            },
            params: {
              page: 1,
              size: pageSize, // use pageSize
              search: "",
              status: "ACTIVE",
              sortColumn: "id",
              sortOrder: "DESC",
              group: "",
            },
          }
        );
        if (response.status === 200) {
          setUsers(
            response.data.content
            .filter(user => !user.userGroups.includes("SuperAdmin"))
            .map((user) => ({
              value: user.id,
              label:
                user.firstName +
                " " +
                user.lastName +
                " (" +
                user.userGroups +
                ")",
            }))
          );
        } else {
          console.error("Error fetching users:", response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [pageSize]); // re-run when pageSize changes
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!projectName) {
      setProjectNameError("Project Name is required");
      return;
    }
    // Check if a project with the same name exists
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}project/list`,
        {
          params: {
            page: 1,
            size: 1000,
            search: "",
            status: "",
            sortColumn: "name",
            sortOrder: "DESC",
          },
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
        }
      );
      if (response.data.content.some(project => project.name === projectName)) {
        setProjectNameError("A project with this name already exists");
        return;
      }
    } catch (error) {
      console.error("Error checking project name:", error);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}project`,
        {
          name: projectName,
        },
        {
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
        }
      );
      if (response.status === 200) {
        const projectId = response.data.id;
        if (selectedUsers.length > 0) {
          await addUsersToProject(projectId);
        }
        triggerRefresh(); // refresh the project list
        handleClose(); // close the modal
      } else {
        console.error("Error adding project:", response);
      }
    } catch (error) {
      console.error("Error adding project:", error);
    }
  };
  const addUsersToProject = async (projectId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}project/users`,
        {
          projectId,
          userIds: selectedUsers.map((user) => user.value),
        },
        {
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
        }
      );
      if (response.status !== 200) {
        console.error("Error adding users to project:", response);
      }
    } catch (error) {
      console.error("Error adding users to project:", error);
    }
  };
  return (
    <div>
      <Modal.Header>
        <Modal.Title>Add Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Project Name</label>
            <input
              type="text"
              className="form-control"
              name="projectName"
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
                setProjectNameError("");
              }}
            />
            {projectNameError && (
              <div className="text-danger">{projectNameError}</div>
            )}
          </div>
          <div className="mb-3">
            <label>Select Users</label>
            <Select
              isMulti
              name="users"
              options={users}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedUsers}
            />
          </div>
          <div className="mb-3 text-end">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={handleClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </Modal.Body>
    </div>
  );
}

export default AddProject;
