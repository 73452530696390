import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import AddUser from "../components/users/AddUser";
import UserList from "../components/users/UserList";
import EditUser from "../components/users/EditUser";
import { Search } from "react-bootstrap-icons";
import {
  getCurrentToken,
  getCurrentUser,
} from "../services/cognito/cognitoAuth";
import axios from "axios";
import Select from "react-select";
import { Tab, Tabs } from "react-bootstrap";
import MyUserList from "../components/users/MyUserList";
import ProjectContext from "../ProjectContext";

function Users() {
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const triggerRefresh = () => setRefreshKey(refreshKey + 1);
  const [activeTab, setActiveTab] = useState("myUsers");
  const { userId, roles } = useContext(ProjectContext);
  const [currentPage, setCurrentPage] = useState(1);

  const handleClose = () => {
    setShow(false);
    setEditUser(null); // reset the editUser state
  };
  const handleShow = () => setShow(true);
  const [editUser, setEditUser] = useState(null);
  const handleEditUser = (user) => {
    setEditUser(user);
    handleShow(); // Open the modal popup
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, activeTab]); 
  return (
    <div className="container-fluid flex-grow-1">
      <div className="d-flex align-items-center justify-content-between py-3">
        <h2 className="fw-bold mb-0">Users</h2>
        <div className="d-flex align-items-center">
          <div className="input-group flex-nowrap mx-2">
            <span
              className="input-group-text bg-transparent"
              id="addon-wrapping"
            >
              <Search />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Users"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
          {roles.includes("SuperAdmin") && (
            <button
              className="btn btn-primary"
              onClick={handleShow}
              style={{ minWidth: "152px" }}
            >
              Add User
            </button>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        {editUser ? (
          <EditUser
            user={editUser}
            handleClose={handleClose}
            triggerRefresh={triggerRefresh}
          />
        ) : (
          <AddUser handleClose={handleClose} triggerRefresh={triggerRefresh} />
        )}
      </Modal>
      {roles.includes("ProjectAdmin") ? (
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          <Tab
            eventKey="myUsers"
            title="My Users" 
          >
            {/* Display the users under the projects of the project manager */}
            <MyUserList
              onEditUser={handleEditUser}
              searchQuery={searchQuery}
              refreshKey={refreshKey}
              activeTab={activeTab}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Tab>
          <Tab
            eventKey="allUsers"
            title="All Users" 
          >
            {/* Display all users */}
            <MyUserList
              onEditUser={handleEditUser}
              searchQuery={searchQuery}
              refreshKey={refreshKey}
              activeTab={activeTab}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Tab>
        </Tabs>
      ) : (
        <UserList
          onEditUser={handleEditUser}
          searchQuery={searchQuery}
          refreshKey={refreshKey}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
}

export default Users;
