import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import Select from "react-select";

function EditTitleForm({
  show,
  handleClose,
  title,
  parentOptions,
  editTitle,
  fetchTitles,
}) {
  const [name, setName] = useState("");
  const [type, setType] = useState(null);
  const [parent, setParent] = useState("");
  const [nameError, setNameError] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [parentError, setParentError] = useState(null);

  const typeOptions = [
    { value: "section", label: "Section" },
    { value: "subsection", label: "Subsection" },
    { value: "Issue nature", label: "Issue Nature" },
    { value: "Issue Type", label: "Issue Type" },
    { value: "Template change type", label: "Template Change Type" },
    { value: "Tag", label: "Tag" },
  ];

  const toTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  useEffect(() => {
    if (title) {
      setName(title.name);
      const titleCaseType = toTitleCase(title.type);
      setType({ value: titleCaseType, label: titleCaseType });

      if (title.parentIssue !== null) {
        setParent({
          value: title.parentIssue.id,
          label: title.parentIssue.name,
        });
      } else {
        setParent(null);
      }
    }
  }, [title]);
  useEffect(() => {
    if (!show) {
      setParent(null);
    }
  }, [show]);
  useEffect(() => {
  }, [parent]);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameError(null);
  };

  const handleTypeChange = (selectedOption) => {
    setType(selectedOption);
    setTypeError(null);
    // If the new type is not 'subsection', clear the parent
    if (selectedOption.value.toLowerCase() !== "subsection") {
      setParent("");
    }
  };

  const handleParentChange = (selectedOption) => {
    setParent(selectedOption);
    setParentError(null);
  };

  const resetForm = () => {
    setName("");
    setType(null);
    setParent(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let hasError = false;

    if (!name) {
      setNameError("Name is required");
      hasError = true;
    }

    if (!type) {
      setTypeError("Type is required");
      hasError = true;
    }

    if (type && type.value === "subsection" && !parent) {
      setParentError("Parent is required");
      hasError = true;
    }

    if (hasError) return;

    const requestBody = {
      issueTitleId: title.id,
      name: name,
      projectId: title.project.id,
      parentId: parent ? parent.value : null,
      type: type.value.toLowerCase(),
    };

    await editTitle(requestBody);
    handleClose();
    resetForm();
    fetchTitles();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Title</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="titleName" className="form-label">
              Title Name
            </label>
            <input
              type="text"
              className="form-control"
              id="titleName"
              value={name}
              onChange={handleNameChange}
            />
            {nameError && <div className="text-danger">{nameError}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="titleType" className="form-label">
              Type
            </label>
            <Select
              options={typeOptions}
              isSearchable={false}
              value={type}
              onChange={handleTypeChange}
            />
            {typeError && <div className="text-danger">{typeError}</div>}
          </div>
          {type && type.value.toLowerCase() === "subsection" && (
            <div className="mb-3">
              <label htmlFor="titleParent" className="form-label">
                Parent
              </label>
              <Select
                key={
                  type && type.value.toLowerCase() === "subsection"
                    ? "subsection"
                    : "other"
                }
                options={parentOptions}
                isSearchable={false}
                value={parent}
                onChange={handleParentChange}
              />

              {parentError && <div className="text-danger">{parentError}</div>}
            </div>
          )}
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditTitleForm;
