import React, {useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";

function UsersProjectList({userId, userName}) {
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        if (userId) {
          fetchUserProjects();
        }
      }, [userId]);
    const fetchUserProjects = async () => {
        try {
          const token = await getCurrentToken();
          if (!token) {
            console.error("No token found");
            return;
          }
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}project/list`,
            {
              headers: {
                Authorization: `Bearer ${await getCurrentToken()}`,
              },
              params: {
                page: 1,
                size: "",
                search: "",
                status: "ACTIVE",
                sortColumn: "lastAccessedOn",
                sortOrder: "DESC",
                userId: userId,
              },
            }
          );
          if (response.status === 200) {
            setProjects(response.data.content);
          } else {
            console.error("Error fetching project users:", response);
          }
        } catch (error) {
          console.error("Error fetching project users:", error);
        }
      };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className="h5">{userName}'s Project List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-group proj-users">
        {projects.map((project) => (
            <li className="list-group-item" key={project.id}>{project.name}</li>
          ))}
        </ul>
      </Modal.Body>
    </>
  );
}

export default UsersProjectList;
