import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import ProjectContext from "../ProjectContext";
import { Modal, Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  EyeFill,
  Search,
  ChevronUp,
  ChevronDown,
  ChevronDoubleLeft,
  PencilSquare,
  Trash,
  Download,
} from "react-bootstrap-icons";
import Pagination from "../components/Pagination";
import Select from "react-select";
import { useLocation, useNavigate, Link } from "react-router-dom";

function IssueList() {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const { projectId, roles, userId, currentPage, setCurrentPage } = useContext(ProjectContext);
  const [selectedIssue, setSelectedIssue] = useState(null);
  //const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const pageSize = 5;
  const [searchName, setSearchName] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [sections, setSections] = useState([]);
  const [subsection, setSubsections] = useState([]);
  const [issueNature, setIssueNature] = useState([]);
  const [issueType, setIssueType] = useState([]);
  const [templateChangeType, setTemplateChangeType] = useState([]);
  const [tag, setTag] = useState([]);
  const [status, setStatus] = useState([{ value: "ACTIVE", label: "Active" }]);
  const [filterSection, setFilterSection] = useState("");
  const [filterSubsection, setFilterSubsection] = useState("");
  const [filterIssueNature, setFilterIssueNature] = useState("");
  const [filterIssueType, setFilterIssueType] = useState("");
  const [filterTemplateChangeType, setFilterTemplateChangeType] = useState("");
  const [filterTag, setFilterTag] = useState("");
  const [applyFilters, setApplyFilters] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [selectedIssueNature, setSelectedIssueNature] = useState(null);
  const [selectedIssueType, setSelectedIssueType] = useState(null);
  const [selectedTemplateChangeType, setSelectedTemplateChangeType] =
    useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [users, setUsers] = useState([]);
  const [filterCreatedBy, setFilterCreatedBy] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [fileCount, setFileCount] = useState(0);
  const [totalListCount, setTotalListCount] = useState(0);
  const location = useLocation();
  const fileId = location.state ? location.state.id : null;
  const navigate = useNavigate();
  const [showResolveAll, setShowResolveAll] = useState(false);
  const [message, setMessage] = useState("");
  const [titles, setTitles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [issueToDelete, setIssueToDelete] = useState(null);
  const [loadedFromLocalStorage, setLoadedFromLocalStorage] = useState(false);
  useEffect(() => {
    // Fetch values from localStorage
    const storedSection = localStorage.getItem("filterSection");
    const storedSubsection = localStorage.getItem("filterSubsection");
    const storedIssueNature = localStorage.getItem("filterIssueNature");
    const storedIssueType = localStorage.getItem("filterIssueType");
    const storedTemplateChangeType = localStorage.getItem(
      "filterTemplateChangeType"
    );
    const storedTag = localStorage.getItem("filterTag");
    const storedStatus = localStorage.getItem("status");
    const storedCreatedBy = localStorage.getItem("filterCreatedBy");
    // Only set state if stored values are not null
    if (storedSection && storedSection !== "null") {
      setSelectedSection(JSON.parse(storedSection));
    }
    if (storedSubsection && storedSubsection !== "null") {
      setSelectedSubsection(JSON.parse(storedSubsection));
    }
    if (storedIssueNature && storedIssueNature !== "null") {
      setSelectedIssueNature(JSON.parse(storedIssueNature));
    }
    if (storedIssueType && storedIssueType !== "null") {
      setSelectedIssueType(JSON.parse(storedIssueType));
    }
    if (storedTemplateChangeType && storedTemplateChangeType !== "null") {
      setSelectedTemplateChangeType(JSON.parse(storedTemplateChangeType));
    }
    if (storedTag && storedTag !== "null") {
      setSelectedTag(JSON.parse(storedTag));
    }
    if (storedStatus && storedStatus !== "null") {
      setStatus(JSON.parse(storedStatus));
    }
    if (storedCreatedBy && storedCreatedBy !== "null") {
      setSelectedUser(JSON.parse(storedCreatedBy));
    }
    setLoadedFromLocalStorage(true);
  }, []);

  const fetchIssues = async () => {
    setLoading(true);
    const statusString = status.map((option) => option.value).join(",");
    try {
      const token = await getCurrentToken();
      const params = {
        page: currentPage,
        size: pageSize,
        search: searchName,
        sortColumn: "id",
        sortOrder: "DESC",
        status: statusString,
        projectId: projectId,
        sectionId: filterSection || selectedSection?.value,
        subsectionId: filterSubsection || selectedSubsection?.value,
        issueNatureId: filterIssueNature || selectedIssueNature?.value,
        issueTypeId: filterIssueType || selectedIssueType?.value,
        templateChangeTypeId:
          filterTemplateChangeType || selectedTemplateChangeType?.value,
        tagId: filterTag || selectedTag?.value,
        createdBy: roles.includes("Reviewer") ? userId : filterCreatedBy || selectedUser?.value,
        fileId: fileId,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/list-issue`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      const parsedIssues = response.data.content.map((issue) => {
        let issueJson = {};
        try {
          if (issue.issueJson) {
            issueJson = JSON.parse(issue.issueJson);
          }
        } catch (error) {
          console.error("Error parsing issueJson:", error);
        }
        return {
          ...issue,
          issueJson,
        };
      });
      const fileCount = response.data.distinctFileCount;
      setFileCount(fileCount);
      setIssues(parsedIssues);
      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 5 && pageInfo.totalElements % 5 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching issues:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (projectId && loadedFromLocalStorage && (applyFilters || currentPage)) {
      fetchIssues();
      // Save the selected filters to localStorage
      if (selectedSection && selectedSection.value) {
        localStorage.setItem("filterSection", JSON.stringify(selectedSection));
      }
      if (selectedSubsection && selectedSubsection.value) {
        localStorage.setItem(
          "filterSubsection",
          JSON.stringify(selectedSubsection)
        );
      }
      if (selectedIssueNature && selectedIssueNature.value) {
        localStorage.setItem(
          "filterIssueNature",
          JSON.stringify(selectedIssueNature)
        );
      }
      if (selectedIssueType && selectedIssueType.value) {
        localStorage.setItem(
          "filterIssueType",
          JSON.stringify(selectedIssueType)
        );
      }
      if (selectedTemplateChangeType && selectedTemplateChangeType.value) {
        localStorage.setItem(
          "filterTemplateChangeType",
          JSON.stringify(selectedTemplateChangeType)
        );
      }
      if (selectedTag && selectedTag.value) {
        localStorage.setItem("filterTag", JSON.stringify(selectedTag));
      }
      if (status && status.length > 0) {
        localStorage.setItem("status", JSON.stringify(status));
      }
      if (selectedUser && selectedUser.value) {
        localStorage.setItem("filterCreatedBy", JSON.stringify(selectedUser));
      }
      setApplyFilters(false);
    }
  }, [
    projectId,
    currentPage,
    searchName,
    applyFilters,
    loadedFromLocalStorage,
  ]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const fetchTitles = async () => {
    try {
      const token = await getCurrentToken();
      const params = {
        page: 1,
        size: "",
        sortColumn: "name",
        sortOrder: "ASC",
        projectId: projectId,
        search: "",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}issue/title-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      const data = response.data.content;
      setTitles(data);
      const sections = response.data.content
        .filter((title) => title.type === "SECTION")
        .map((title) => ({ value: title.id, label: title.name }));
      setSections(sections);
      const subsections = response.data.content
        .filter((title) => title.type === "SUBSECTION")
        .map((title) => ({ value: title.id, label: title.name }));
      setSubsections(subsections);
      const issueNatures = response.data.content
        .filter((title) => title.type === "ISSUE_NATURE")
        .map((title) => ({ value: title.id, label: title.name }));
      setIssueNature(issueNatures);
      const issueTypes = response.data.content
        .filter((title) => title.type === "ISSUE_TYPE")
        .map((title) => ({ value: title.id, label: title.name }));
      setIssueType(issueTypes);
      const templateChangeTypes = response.data.content
        .filter((title) => title.type === "TEMPLATE_CHANGE_TYPE")
        .map((title) => ({ value: title.id, label: title.name }));
      setTemplateChangeType(templateChangeTypes);
      const tags = response.data.content
        .filter((title) => title.type === "TAG")
        .map((title) => ({ value: title.id, label: title.name }));
      setTag(tags);
    } catch (error) {
      console.error("Error fetching titles:", error);
      setLoading(false);
    }
  };
  const fetchUsers = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            projectId: projectId,
          },
        }
      );
      const users = response.data.content.filter(
        (user) => !user.userGroups.includes("SuperAdmin")
      );

      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const clearFilters = () => {
    setSelectedSection(null);
    setSelectedSubsection(null);
    setSelectedIssueNature(null);
    setSelectedIssueType(null);
    setSelectedTemplateChangeType(null);
    setSelectedTag(null);
    setSelectedUser(null);
    setFilterSection("");
    setFilterSubsection("");
    setFilterIssueNature("");
    setFilterIssueType("");
    setFilterTemplateChangeType("");
    setFilterTag("");
    setFilterCreatedBy("");
    setStatus([{ value: "ACTIVE", label: "Active" }]);
    setApplyFilters(true);
    setShowResolveAll(false);
    localStorage.removeItem("filterSection");
    localStorage.removeItem("filterSubsection");
    localStorage.removeItem("filterIssueNature");
    localStorage.removeItem("filterIssueType");
    localStorage.removeItem("filterTemplateChangeType");
    localStorage.removeItem("filterTag");
    localStorage.removeItem("status");
    localStorage.removeItem("filterCreatedBy");
  };
  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("filterSection");
      localStorage.removeItem("filterSubsection");
      localStorage.removeItem("filterIssueNature");
      localStorage.removeItem("filterIssueType");
      localStorage.removeItem("filterTemplateChangeType");
      localStorage.removeItem("filterTag");
      localStorage.removeItem("status");
      localStorage.removeItem("filterCreatedBy");
    };
  
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  const handleClick = () => {
    const pathname = roles.includes("Reviewer") ? "/files" : "/documents";
    navigate(pathname, { state: { fileId: fileId, currentPage: currentPage } });
  };
  const resolveSingleIssue = async (fileId, id) => {
    try {
      const token = await getCurrentToken();
      const payload = {
        fileId: fileId,
        fileIssueId: id,
        userId: userId,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/resolve-all`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Handle response here
      fetchIssues(); // Refresh the issues list
      setMessage("The issue has been resolved.");
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error resolving single issue:", error);
    }
  };
  const resolveAllIssues = async () => {
    try {
      const token = await getCurrentToken();
      const payload = {
        fileId: fileId,
        projectId: projectId,
        sectionId: filterSection,
        subsectionId: filterSubsection,
        issueNatureId: filterIssueNature,
        issueTypeId: filterIssueType,
        templateChangeTypeId: filterTemplateChangeType,
        descriptionTitle: null,
        search: null,
        createdBy: filterCreatedBy,
        userId: userId,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/resolve-all`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Handle response here
      fetchIssues(); // Refresh the issues list
      clearFilters();
      setMessage("All issues have been resolved.");
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error resolving all issues:", error);
    }
  };
  const handleEdit = (issue) => {
    navigate(`/edit-issue/${issue.id}`, { state: { issue } });
  };
  const handleDelete = (issue) => {
    setIssueToDelete(issue);
    setShowModal(true);
  };
  const deleteIssue = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/delete-issue`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            fileIssueId: issueToDelete.id,
          },
        }
      );
      fetchIssues();
      setShowModal(false);
      setMessage("The issue has been deleted.");
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error deleting issue:", error);
    }
  };
  useEffect(() => {
    if (selectedSection) {
      const filteredSubsections = titles
        .filter(
          (item) =>
            item.type === "SUBSECTION" &&
            item.parentIssue.id === selectedSection.value
        )
        .map((subsection) => ({
          value: subsection.id,
          label: subsection.name,
        }));
      setSubsections(filteredSubsections);
    } else {
      const allSubsections = titles
        .filter((item) => item.type === "SUBSECTION")
        .map((subsection) => ({
          value: subsection.id,
          label: subsection.name,
        }));
      setSubsections(allSubsections);
    }
  }, [selectedSection, titles]);
  const downloadIssues = async () => {
    const statusString = status.map((option) => option.value).join(",");
    const params = {
      search: searchName,
      sortColumn: "id",
      sortOrder: "DESC",
      status: statusString,
      projectId: projectId,
      sectionId: filterSection || selectedSection?.value,
      subsectionId: filterSubsection || selectedSubsection?.value,
      issueNatureId: filterIssueNature || selectedIssueNature?.value,
      issueTypeId: filterIssueType || selectedIssueType?.value,
      templateChangeTypeId:
        filterTemplateChangeType || selectedTemplateChangeType?.value,
      tagId: filterTag || selectedTag?.value,
      createdBy: roles.includes("Reviewer") ? userId : filterCreatedBy,
      fileId: fileId,
    };
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/export-issues`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );

      const url = response.data;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting issues:", error);
    }
  };
  const handleViewFile = async (filePathJson, name, currentPage) => {
    navigate("/file-viewer", {
      state: { filePathJson, currentPage, name },
    });
  };
  return (
    <div>
      <div className="pt-3 pb-2 d-flex align-items-center justify-content-between">
        <h2 className="fw-bold mb-0">Issues</h2>
        <div className="d-flex align-items-center">
          {location.state && location.state.id && (
            <button
              onClick={handleClick}
              className="btn btn-sm btn-outline-primary px-3"
              style={{ minWidth: "100px" }}
            >
              <ChevronDoubleLeft  class="dark-icon-color"/> Back
            </button>
          )}
          <div className="input-group flex-nowrap mx-2">
            <span
              className="input-group-text bg-transparent"
              id="addon-wrapping"
            >
              <Search />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search File Name"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
          <button
            onClick={async () => {
              setShowFilters(!showFilters);
              if (!showFilters) {
                fetchTitles();
                fetchUsers();
              }
            }}
            aria-controls="filter-collapse"
            aria-expanded={showFilters}
            className="btn border-0 px-2 fw-bold text-primary"
            style={{ minWidth: "130px" }}
          >
            {showFilters ? (
              <>
                <ChevronUp /> Hide
              </>
            ) : (
              <>
                <ChevronDown /> Show
              </>
            )}{" "}
            Filters
          </button>
        </div>
      </div>
      <Collapse in={showFilters}>
        <div id="filter-collapse" className="border rounded-3 p-4">
          <div className="row">
            {roles.includes("ProjectAdmin") && (
              <div className="col-md-4">
                <label className="form-label">Created by</label>
                <Select
                  options={users.map((user) => ({
                    value: user.id,
                    label: `${user.firstName} ${user.lastName}`,
                  }))}
                  value={selectedUser}
                  isClearable
                  placeholder="Select User"
                  className="mb-3"
                  onChange={(selectedOption) => {
                    setSelectedUser(selectedOption);
                    setFilterCreatedBy(
                      selectedOption ? selectedOption.value : ""
                    );
                  }}
                />
              </div>
            )}
            <div className="col-md-4">
              <label className="form-label">Section</label>
              <Select
                options={sections}
                value={selectedSection}
                isClearable
                placeholder="Select Section"
                className="mb-3"
                onChange={(selectedOption) => {
                  setSelectedSection(selectedOption);
                  setFilterSection(selectedOption ? selectedOption.value : "");
                }}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Subsection</label>
              <Select
                options={subsection}
                value={selectedSubsection}
                isClearable
                placeholder="Select Subsection"
                className="mb-3"
                onChange={(selectedOption) => {
                  setSelectedSubsection(selectedOption);
                  setFilterSubsection(
                    selectedOption ? selectedOption.value : ""
                  );
                }}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Issue Nature</label>
              <Select
                options={issueNature}
                value={selectedIssueNature}
                isClearable
                placeholder="Select Issue Nature"
                className="mb-3"
                onChange={(selectedOption) => {
                  setSelectedIssueNature(selectedOption);
                  setFilterIssueNature(
                    selectedOption ? selectedOption.value : ""
                  );
                }}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Issue Type</label>
              <Select
                options={issueType}
                value={selectedIssueType}
                isClearable
                placeholder="Select Issue Type"
                className="mb-3"
                onChange={(selectedOption) => {
                  setSelectedIssueType(selectedOption);
                  setFilterIssueType(
                    selectedOption ? selectedOption.value : ""
                  );
                }}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Template Change Type</label>
              <Select
                options={templateChangeType}
                value={selectedTemplateChangeType}
                isClearable
                placeholder="Select Template Change Type"
                className="mb-3"
                onChange={(selectedOption) => {
                  setSelectedTemplateChangeType(selectedOption);
                  setFilterTemplateChangeType(
                    selectedOption ? selectedOption.value : ""
                  );
                }}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Tag</label>
              <Select
                options={tag}
                value={selectedTag}
                isClearable
                placeholder="Select Tag"
                className="mb-3"
                onChange={(selectedOption) => {
                  setSelectedTag(selectedOption);
                  setFilterTag(selectedOption ? selectedOption.value : "");
                }}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Status</label>
              <Select
                options={[
                  { value: "ACTIVE", label: "Active" },
                  { value: "RESOLVED", label: "Resolved" },
                ]}
                value={status}
                isClearable
                isMulti
                placeholder="Select Status"
                className="mb-3"
                onChange={(selectedOptions) => setStatus(selectedOptions || [])}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button onClick={clearFilters} className="btn btn-secondary me-2">
              Clear
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                setApplyFilters(true);
                setShowResolveAll(true);
              }}
            >
              Filter
            </button>
          </div>
        </div>
      </Collapse>
      {roles.includes("ProjectAdmin") && (
        <div className="text-end mt-3">
          {showResolveAll && (
            <button
              onClick={resolveAllIssues}
              className="btn btn-sm btn-success"
            >
              Resolve All
            </button>
          )}
        </div>
      )}
      {message && (
        <div className="alert alert-success mt-3" role="alert">
          {message}
        </div>
      )}
      <div className="table-responsive border rounded p-2 mt-3">
        <div className="d-flex justify-content-between my-1 align-items-center">
          <div className="ps-2">
            File Count:{" "}
            <strong className="badge rounded-pill text-bg-primary">
              {fileCount}
            </strong>
          </div>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Export Issues</Tooltip>}
          >
            <button
              className="btn btn-sm btn-primary px-3"
              onClick={downloadIssues}
            >
              <Download size={18} />
            </button>
          </OverlayTrigger>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : issues.length > 0 ? (
          <table className="table table-borderless table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th style={{ width: "25%" }}>File Name</th>
                <th>File Id</th>
                <th>Issue Id</th>
                <th>Issue</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {issues.map((issue) => (
                <tr key={issue.id}>
                  <td>
                    {issue.firstName} {issue.lastName}
                  </td>
                  <td>
                    <button
                      to={""}
                      onClick={() =>
                        handleViewFile(
                          issue.filePathJson,
                          issue.name,
                          currentPage
                        )
                      }
                      className="btn-link bg-transparent text-start border-0 text-primary"
                    >
                      {issue.name}
                    </button>
                  </td>
                  <td>{issue.fileId}</td>
                  <td>{issue.id}</td>
                  <td>
                    {issue.issueJson && (
                      <ul className="ps-3 m-0">
                        {issue.issueJson.section && (
                          <li>
                            Section: <strong>{issue.issueJson.section}</strong>
                          </li>
                        )}
                        {issue.issueJson.subsection && (
                          <li>
                            Subsection:{" "}
                            <strong>{issue.issueJson.subsection}</strong>
                          </li>
                        )}
                        {issue.issueJson.issueNature && (
                          <li>
                            Issue Nature:{" "}
                            <strong>{issue.issueJson.issueNature}</strong>
                          </li>
                        )}
                        {issue.issueJson.issueType && (
                          <li>
                            Issue Type:{" "}
                            <strong>{issue.issueJson.issueType}</strong>
                          </li>
                        )}
                        {issue.issueJson.templateChangeType && (
                          <li>
                            Template Change Type:{" "}
                            <strong>
                              {issue.issueJson.templateChangeType}
                            </strong>
                          </li>
                        )}
                        {issue.issueJson.tag && (
                          <li>
                            Tag: <strong>{issue.issueJson.tag}</strong>
                          </li>
                        )}
                      </ul>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => setSelectedIssue(issue)}
                      className="btn btn-sm px-2 py-1 btn-outline-primary"
                      disabled={!issue.description}
                    >
                      <EyeFill size={20} />
                    </button>
                  </td>
                  <td>{issue.status}</td>
                  <td>
                    {roles.includes("ProjectAdmin") &&
                      issue.status !== "RESOLVED" && (
                        <button
                          className="btn btn-sm btn-success px-3"
                          onClick={() =>
                            resolveSingleIssue(issue.fileId, issue.id)
                          }
                        >
                          Resolve
                        </button>
                      )}
                    {roles.includes("Reviewer") && (
                      <>
                        <button
                          className="btn btn-link"
                          onClick={() => handleEdit(issue)}
                        >
                          <PencilSquare size={18} />
                        </button>
                        <button
                          className="btn btn-link text-danger"
                          onClick={() => handleDelete(issue)}
                        >
                          <Trash size={18} />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No list found.</p>
        )}
        <Pagination
          currentPage={currentPage}
          totalPageSize={totalPageSize}
          onPageChange={handlePageChange}
          totalListCount={totalListCount}
          pageSize={pageSize}
        />
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this issue?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => setShowModal(false)}
            >
              No
            </button>
            <button className="btn btn-sm btn-danger" onClick={deleteIssue}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>
        {selectedIssue && (
          <Modal
            show={selectedIssue}
            size="lg"
            onHide={() => setSelectedIssue(null)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Issue Description</Modal.Title>
            </Modal.Header>
            <Modal.Body className="issue-desc">
              <div
                dangerouslySetInnerHTML={{ __html: selectedIssue.description }}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={() => setSelectedIssue(null)}
                className="btn btn-sm btn-secondary"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
}
export default IssueList;
